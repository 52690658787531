import { defineStore } from 'pinia'
import { createRouter, RouteLocationRaw, createWebHistory } from 'vue-router'
import { GlobalRoutes } from '../routes/routes'
import { ROUTE_STORE } from '../constants/store.id'

export const useRoute = defineStore({
  id: ROUTE_STORE,
  state: () => ({
    router: createRouter({
      history: createWebHistory(import.meta.env.BASE_URL),
      routes: GlobalRoutes
    })
  }),
  actions: {
    id(): number {
      const params = this.params()
      if (params.id === undefined) {
        return 0
      }

      return Number(params.id)
    },
    uuid(): string {
      const params = this.params()
      if (params.id === undefined) {
        return ''
      }

      return params.id as string
    },
    bankId(): number {
      const params = this.params()
      console.log('route state params, bank id:', params.bankId, params)
      if (params.bankId === undefined) {
        return 0
      }

      return Number(params.bankId)
    },
    productId(): number {
      const params = this.params()
      console.log(params)
      if (params.pid === undefined && params.productId === undefined) {
        return 0
      }

      return Number(params.pid || params.productId)
    },
    goodsId(): number {
      const params = this.params()
      if (params.gid === undefined) {
        return 0
      }

      return Number(params.gid)
    },
    orderId(): number {
      const params = this.params()
      if (params.orderId === undefined) {
        return 0
      }

      return Number(params.orderId)
    },
    userId(): number {
      const params = this.params()
      if (params.userId === undefined) {
        return 0
      }

      return Number(params.userId)
    },
    accessGroupId(): number {
      const params = this.params()
      if (params.groupId === undefined) {
        return 0
      }

      return Number(params.groupId)
    },
    accessRoleId(): number {
      const params = this.params()
      if (params.roleId === undefined) {
        return 0
      }

      return Number(params.roleId)
    },
    agentId(): number {
      const params = this.params()
      if (params.agentId === undefined) {
        return 0
      }

      return Number(params.agentId)
    },
    current() {
      return this.router.currentRoute
    },
    name() {
      return this.current().name
    },
    fullPath() {
      return this.current().fullPath
    },
    params() {
      return this.current().params
    },
    matched() {
      if (this.router === undefined || this.current() === undefined) {
        return []
      }

      return this.current().matched
    },
    meta() {
      return this.current().meta
    },
    path() {
      return this.current().path
    },
    query() {
      return this.current().query
    },
    push(route: string | RouteLocationRaw) {
      this.router.push(route)
    },
    replace(router: RouteLocationRaw) {
      this.router.replace(router)
    }
  }
})
