import { LoginView, component } from '../utils/route.utils'

import { ICON_SVG } from '../icon.svg'
import { RouteRecordRaw } from 'vue-router'

export const ChannelName = 'login'
export const ChannelLabel = '登录'
export const LoginRoute: RouteRecordRaw = {
  name: `${ChannelName}`,
  path: `/${ChannelName}`,
  component: LoginView,
  meta: {
    label: '登录',
    icon: 'OfficeBuilding',
    group: { name: ChannelName, label: ChannelLabel },
    showInLeftMenu: false,
    href: (d: any) => {
      return '/login'
    },
    svg: ICON_SVG.bank
  },
  children: [
    {
      name: 'login-index',
      path: `/${ChannelName}`,
      component: component(ChannelName, 'Index'),
      meta: {
        label: '登录',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        href: () => {
          return `/login`
        },
        showInLeftMenu: false,
        svg: ICON_SVG.goods
      }
    }
  ]
}
