
import { LoginRoute } from './access/login.route'
import { RouteRecordRaw } from 'vue-router'
import { AgentRoute } from './dfq/agent.route'
import { AgentOrderRoute } from './dfq/agent.order.route'
import { AgentUserRoute } from './dfq/agent.user.route'
import { InternalRootView } from './utils/route.utils'

const agentRoutes = [AgentRoute, AgentOrderRoute]
agentRoutes.forEach(v => {
  v.component = InternalRootView
})

export const GlobalRoutes: RouteRecordRaw[] = [LoginRoute, ...agentRoutes]
